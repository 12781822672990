<template>
  <v-card class="pa-4">
    <div class="form-section-heading">FAQs</div>
    <SectionsContent id="event-faqs">
      <template #section-content="{ model, valid, remove }">
        <v-dynamic-form
          v-model="model.value"
          v-model:valid="valid.value"
          :inputs="inputs"
          class="pa-4"
        />
        <v-divider />
        <div class="px-4 py-2 highlight flex justify-end">
          <v-btn @click="remove" class="text-none" variant="text"
            >Remove item</v-btn
          >
        </div>
      </template>
    </SectionsContent>
  </v-card>
</template>

<script lang="ts">
import { SimpleEditor } from "#components";
import { defineComponent } from "vue";
import { Rule } from "~/services/Rules";

export default defineComponent({
  props: {
    emptyDescription: {
      type: String,
      default:
        "Answer your customers before they ask. Add Frequently Asked Questions here.",
    },
  },
  setup(props) {
    const { data, getValid } = useSaveContext<any>();
    const valid = getValid("faqs");

    useSectionContent<any>({
      id: "event-faqs",
      identify: true,
      sortable: true,
      itemTitle: "question",
      emptyIcon: "question_answer",
      emptyDescription: props.emptyDescription,
      items: {
        set(items) {
          data.value.faqs = items;
        },
        get() {
          return data.value.faqs || [];
        },
        setValid(key: string, value: boolean) {
          valid.value[key] = value;
        },
        getValid(key: string) {
          return valid.value[key];
        },
        make: () => {
          const position = data.value.faqs?.length || 0;
          return {
            question: "Question " + (position + 1),
            position,
          };
        },
      },
    });
  },
  data: () => ({
    inputs: defineFormInputs({
      question: {
        name: "Question",
        rules: Rule.group([Rule.required(), Rule.title()]),
        type: "text",
      },
      answer: {
        name: "Answer",
        rules: Rule.group([Rule.required(), Rule.string.min(5), Rule.short()]),
        component: SimpleEditor,
        props: {
          counter: 320,
          height: 150,
        },
      },
    }),
  }),
});
</script>
