import { VSelect } from "vuetify/components";
import { omit } from "lodash";

const ages = ["17+", "18+", "19+", "20+", "21+"].map((v) => ({
  title: v,
  value: v,
}));

export default defineComponent({
  name: "AgeRestrictionSelect",
  props: {
    ...VSelect.props,
    modelValue: { type: String },
    placeholder: { type: String, default: "All ages" },
    noneTitle: { type: String, default: "All ages" },
    noneValue: {},
  },
  computed: {
    props() {
      return omit(this.$props, [
        "modelValue",
        "items",
        "noneTitle",
        "itemValue",
      ]);
    },
    items_() {
      return [{ title: this.noneTitle, value: this.noneValue }, ...ages];
    },
  },
  render() {
    return h(
      VSelect,
      {
        ...this.props,
        items: this.items_,
        itemValue: "value",
        modelValue: this.modelValue,
        "onUpdate:modelValue": (v: string) => {
          this.$emit("update:modelValue", v);
        },
      },
      {
        selection: ({ item }: any) => {
          return h("span", item ? item.title : this.noneTitle);
        },
      }
    );
  },
});
